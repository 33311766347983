import './App.css';
import {
  MarketingPage 
 } from './ui-components';

function App() {
  return (
      <MarketingPage />
  );
}

export default App;
